body {
    font-family: Radikal-Regular;
}

.container {
    margin: 24px 24px 0 24px;
}

a {
    text-decoration: none;
}

.testlab {
    color: #000;
    text-decoration: none;
    margin-top: -8px;
    margin-left: 6px;
    font-family: Radikal-Bold;
    font-size: 16px;
}

.circle {
    position: fixed;
    border-radius: 50%;
    z-index: -1;
    overflow: hidden;
}

.big {
    width: 300px;
    height: 300px;
    right: -220px;
    top: 160px;
    background-color: #fad232;
}

.small {
    width: 40px;
    height: 40px;
    left: 5%;
    top: 72px;
    background-color: #28c8c8;
}

.logged-in-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.logged-out-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    bottom: 20%;
}

.logo-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.login-message {
    top: 70%;
    font-family: Radikal-Bold;
}

.device-info {
    margin-top: 3rem;
    justify-content: center;
    text-align: center;
}

.os-info {
    font-family: Radikal-Medium;
    font-size: 16px;
    margin-left: 4px;
}

.device-details-row {
    display: flex;
    justify-content: center;
}

.logo {
    margin-top: 7px;
    margin-right: 7px;
}

.button {
    border: solid;
    border-radius: 24px;
    color: #28c8c8;
    border-color: #28c8c8;
    border-width: 2px;
    background-color: transparent;
    padding: 8px 16px 8px 16px;
    font-family: Radikal-Medium;
    margin: 12px 0 12px 0;
    font-size: 16px;
}

.red-button {
    border-color: #fa3282;
    color: #fa3282;
}

.prev-owner {
    color: #5c5c5c;
    margin: 8px 0 8px 0;
}

.success-message {
    font-family: Radikal-Bold;
    font-size: 25px;
    margin-bottom: 1rem;
}

.device-name {
    font-family: Radikal-Bold;
    font-size: 16px;

    margin-right: 4px;
}

.username {
    margin: 8px 0 8px 0;
    font-size: 25px;
}

.profile-image {
    margin: 16px 0 8px 0;
    border-radius: 32px;
    width: 64px;
    height: 64px;
}

@font-face {
    font-family: "Radikal-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/radikal/Nootype - Radikal-Regular.otf");
}

@font-face {
    font-family: "Radikal-Bold";
    font-weight: 600;
    src: url("./assets/fonts/radikal/Nootype - Radikal Bold.otf");
}

@font-face {
    font-family: "Radikal-Medium";
    font-weight: medium;
    src: url("./assets/fonts/radikal/Nootype - Radikal-Medium.otf");
}

.my-device-title {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 18px;
}

.my-device-list-container {
    width: 100%;
}

.my-device-list {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
}

.camera-text {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.qr-icon {
    margin-right: 8px;
}

.delivery-icon {
    background-color: #8232fa;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 14px;
    margin-bottom: 8px;
}

.thank-you {
    font-size: 16px;
    margin-bottom: 5px;
}

.delivery-pink {
    background-color: #fa3282;
}

.loading-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    position: absolute;
    bottom: 40%;
    animation: spin 2s linear infinite;
    width: 30px;
    height: 30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
